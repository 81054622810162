<template>
  <div></div>
</template>

<script>

export default {
  computed: {
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
  },
  async mounted() {
    this.$store.commit("userStore/setOpenPaymentLinkSkipPrompt", false);
    this.$store.dispatch("authStore/logoutUser");
    this.$store.dispatch("businessStore/removeTestMode");
    window.location.href = "/login";
  },
};
</script>